.adyen-checkout__field {
  display: block;
  margin-bottom: 16px;
  width: 100%; }

.adyen-checkout__field:last-of-type {
  margin-bottom: 0; }

.adyen-checkout__label {
  display: block; }

.adyen-checkout__label__text,
.adyen-checkout__helper-text {
  color: #00112c;
  display: block;
  font-size: 0.81em;
  font-weight: normal;
  line-height: 13px;
  padding-bottom: 5px; }

.adyen-checkout__helper-text {
  color: #687282; }

.adyen-checkout__label__text {
  transition: color 0.1s ease-out;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.adyen-checkout__label__text--error {
  color: #d10244; }

.adyen-checkout__label--focused .adyen-checkout__label__text {
  color: #06f; }

.adyen-checkout__error-text {
  display: flex;
  color: #d10244;
  font-weight: normal;
  margin-top: 4px;
  font-size: 0.75em;
  align-items: center; }
