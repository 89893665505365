.adyen-checkout__order-payment-methods-list {
  list-style: none;
  margin: 0 auto 16px;
  padding: 0; }

.adyen-checkout__order-payment-method {
  position: relative;
  background: #fff;
  border: 1px solid #e6e9eb;
  margin-top: -1px;
  width: 100%; }

.adyen-checkout__order-payment-method:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px; }

.adyen-checkout__order-payment-method:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px; }

.adyen-checkout__order-payment-method__header {
  align-items: center;
  color: #00112c;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1em;
  padding: 16px;
  position: relative;
  transition: background .1s ease-out;
  width: 100%; }

.adyen-checkout__order-payment-method__details {
  padding: 0 16px 16px; }

.adyen-checkout__order-payment-method__deducted-amount {
  display: flex;
  justify-content: space-between;
  line-height: 1em;
  font-size: 1em; }

.adyen-checkout__order-payment-method__deducted-amount__label {
  font-size: 0.81em; }

.adyen-checkout__order-payment-method__deducted-amount__value {
  font-weight: 500; }

.adyen-checkout__order-remaining-amount {
  display: block;
  width: 100%;
  padding: 8px 16px;
  color: #7f4a00;
  background: #ffeacc;
  border-radius: 6px;
  margin-bottom: 16px;
  font-size: 0.81em; }
  .adyen-checkout__order-remaining-amount strong {
    font-weight: bold; }
