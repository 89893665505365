@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    cursor: pointer; }
  .apple-pay-button-black {
    -apple-pay-button-style: black; }
  .apple-pay-button-white {
    -apple-pay-button-style: white; }
  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline; }
  /* Apple Pay Button types https://developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons */
  .apple-pay-button--type-plain {
    -apple-pay-button-type: plain; }
  .apple-pay-button--type-buy {
    -apple-pay-button-type: buy; }
  .apple-pay-button--type-donate {
    -apple-pay-button-type: donate; }
  .apple-pay-button--type-check-out {
    -apple-pay-button-type: check-out; }
  .apple-pay-button--type-book {
    -apple-pay-button-type: book; }
  .apple-pay-button--type-subscribe {
    -apple-pay-button-type: subscribe; }
  .apple-pay-button--type-add-money {
    -apple-pay-button-type: add-money; }
  .apple-pay-button--type-contribute {
    -apple-pay-button-type: contribute; }
  .apple-pay-button--type-order {
    -apple-pay-button-type: order; }
  .apple-pay-button--type-reload {
    -apple-pay-button-type: reload; }
  .apple-pay-button--type-rent {
    -apple-pay-button-type: rent; }
  .apple-pay-button--type-support {
    -apple-pay-button-type: support; }
  .apple-pay-button--type-tip {
    -apple-pay-button-type: tip; }
  .apple-pay-button--type-top-up {
    -apple-pay-button-type: top-up; } }

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px; }
  .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black; }
  .apple-pay-button-white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white; }
  .apple-pay-button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: 0.5px solid black; } }
